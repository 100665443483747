module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NT Markets","short_name":"NT Markets","icon":"src/assets/images/favicons/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5ae3bc201d864db6e04647e07836a1af"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","am","ru"],"defaultLanguage":"en","fallbackLng":"en","siteUrl":"https://ntmarkets.am","i18nextOptions":{"interpolation":{"escapeValue":false},"react":{"transKeepBasicHtmlNodesFor":["br","span"]},"debug":false},"pages":[{"matchPath":"/:lang?/404","getLanguageFromPath":false}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":94238406,"trackHash":true,"afterBody":true,"defer":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-jss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
